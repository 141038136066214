import axios from 'axios'
export default {
    state: {
        trasporto: {},
        soglie_orari: {}
    },
    getters: {
        get_trasporto: state => {
            return state.trasporto
        },
        get_soglie_orari: state => {
            return state.soglie_orari
        }
    },
    mutations: {
        set_trasporto(state, trasporto) {
            state.trasporto = trasporto
        },
        set_soglie_orari(state, soglie) {
            state.soglie_orari = soglie
        }
    },
    actions: {
        fetch_trasporto(context) {
            return new Promise((resolve, reject) => {
                let url = process.env.VUE_APP_API_ROOT + '/trasporto'
                let auth = context.getters.get_credential
                axios.get(url, { 'auth': auth })
                    .then(res => {
                        context.commit('set_trasporto', res.data)
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetch_soglie_orari(context) {
            return new Promise((resolve, reject) => {
                let url = process.env.VUE_APP_API_ROOT + '/orari'
                let auth = context.getters.get_credential
                axios.get(url, { 'auth': auth })
                    .then(res => {
                        context.commit('set_soglie_orari', res.data)
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    }
}