//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import fetch_all from "./service/fetch_all";
import moment from "moment";
// import subscription_mixin from "./app_mixin/subscription.js";
import firebase_mixin from "./app_mixin/firebase_msg.js";
import sw_update_mixin from "./app_mixin/update.js";
import pwa_mixin from "./app_mixin/pwa.js";
import Login from "./components/login/login.vue";
import platform from 'platform'
export default {
  name: "App",

  components: {
    Login,
    BarMobile: () =>
      import(
        /* webpackChunkName: "bars" */ "./components/app_bar/mobile/app_bar_mobile"
      ),
    BarDesktop: () =>
      import(
        /* webpackChunkName: "bars" */ "./components/app_bar/desktop/app_bar_desktop"
      ),
    SideBar: () =>
      import(
        /* webpackChunkName: "bars" */ "./components/app_bar/side_bar_desktop"
      ),
    ChatDesktop: () =>
      import("./components/chat/desktop/chat_main_desktop.vue"),
    ChatMobile: () => import("./components/chat/mobile/chat_main_mobile.vue"),
    Recupero: () => import("./components/recupero_pwd/recupero_pwd.vue"),
    ConvalidaPwd: () => import("./components/recupero_pwd/convalida_pwd.vue"),
    Registrazione: () => import("./components/recupero_pwd/registrazione.vue"),
  },
  mixins: [firebase_mixin, sw_update_mixin, pwa_mixin],
  sockets: {
    connect: function () {
      this.io_connected = true;
    },
    disconnect: function () {
      this.io_connected = false;
      var reconnect_interval = setInterval(() => {
        if (this.io_connected || !this.isAuth || !this.hasFocus) {
          clearInterval(reconnect_interval);
        } else {
          this.connetti_socket();
        }
      }, 2000);
    },
  },
  mounted() {
    // controllo la provenienza della pagina
    moment.locale("it");

    if (this.$route.path == "/password_reset") {
      this.convalida_pwd = true;
      return;
    }
    if (this.$route.path == "/register") {
      this.register = true;
      return;
    }
    this.loading = true;

    // controllo se il token è presente

    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") == null
    ) {
      this.loading = false;
      return;
    }
    let auth = {
      username: localStorage.getItem("token"),
      password: "",
    };
    this.verifica_credenziali(auth);
  },

  data: () => ({
    loading: false,
    show_chat: false,
    restore: false,
    convalida_pwd: false,
    register: false,
    drawbar: false,
    io_connected: false,
    hasFocus: true,
  }),

  computed: {
    ...mapGetters({
      isAuth: "get_isAuth",
      n_non_letti: "get_chat_nl",
      chat_loading: "get_chat_loading",
    }),
    version() {
      return process.env.VUE_APP_VERSION;
    },
  },
  methods: {
    conferma_reg: function (auth) {
      this.verifica_credenziali(auth);
      this.$router.push("/");
      this.register = false;
    },
    login_ok() {
      this.connetti_socket();
      this.focus_watcher();
      fetch_all.fetch();
      this.checkSubscripions();
    },
    verifica_credenziali(auth) {
      this.$store
        .dispatch("verifica_credenziali", auth)
        .then(() => {
          this.connetti_socket();
          this.focus_watcher();
          fetch_all.fetch();
          this.checkSubscripions();
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    connetti_socket() {
      this.$socket.io.opts.query = { token: this.$store.getters.get_token, device: platform };
      this.$socket.open();
    },
    focus_watcher() {
      var t;
      const runTimer = () => {
        t = setTimeout(() => {
          this.$socket.close();
        }, 30000);
      };
      runTimer();
      setInterval(() => {
        if (
          document.hasFocus() &&
          !this.hasFocus &&
          this.$socket.disconnected &&
          this.isAuth
        ) {
          this.$store.dispatch("fetch_prodotti")
          this.$store.dispatch("fetch_storico_chat").then(() => {
            this.$socket.open();
          });
        }
        this.hasFocus = document.hasFocus();
        if (this.hasFocus) {
          clearTimeout(t);
          runTimer();
        }
      }, 5000);
    },
  },
};
