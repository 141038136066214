import store from '../store/index'

export default {
    fetch() {
        return new Promise((resolve) => {
            store.dispatch("fetch_prodotti").then(() => {
                resolve()
                store.dispatch("fetch_carrello");
                store.dispatch("fetch_trasporto");
                store.dispatch("fetch_soglie_orari");
                store.dispatch("fetch_lista_ordini", {});
                //store.dispatch("fetch_messaggi");
                store.dispatch("fetch_storico_chat");
                store.dispatch("fetch_destinatari");
                
            })
        })

    }
}