//
//
//
//
//

import { mapGetters } from "vuex";
export default {
  components: {
    ListaDesktop: () =>
      import("@/components/lista/desktop/listino_main_desktop.vue"),
    ListaMobile: () =>
      import("@/components/lista/mobile/listino_main_mobile.vue"),
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      lista: "get_lista_prodotti",
      prod_dect_state: "get_prod_dect",
    }),
  },
};
