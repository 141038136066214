import axios from "axios";

export default {
  state: {
    utente: {},
    token: "",
    isAuth: false,
  },
  getters: {
    get_credential: (state) => {
      return {
        username: state.token,
        password: "",
      };
    },
    get_isAuth: (state) => {
      return state.isAuth;
    },
    get_utente: (state) => {
      return state.utente;
    },
    get_token: (state) => {
      return state.token;
    },
    get_destinazioni: (state) => {
      return state.utente.destinazioni;
    },
  },
  mutations: {
    set_utente(state, utente) {
      state.utente = utente;
    },
    set_token(state, token) {
      state.token = token;
    },
    set_isAuth(state, isAuth) {
      state.isAuth = isAuth;
    },
  },
  actions: {
    verifica_credenziali(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/token";
        let auth = {
          username: payload.username,
          password: payload.password,
        };
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_token", res.data.token);
            context.commit("set_utente", res.data.user);
            localStorage.setItem("token", res.data.token);
            context.commit("set_isAuth", true);
            resolve({ status: 200 });
          })
          .catch((err) => {
            context.commit("set_token", "");
            context.commit("set_isAuth", false);
            context.commit("set_utente", {});
            if (err.response) {
              resolve({ status: err.response.status, data: "" });
            } else {
              reject(err);
            }
          });
      });
    },
    logout(context) {
      context.commit("set_token", "");
      context.commit("set_isAuth", false);
      context.commit("set_utente", {});
      localStorage.setItem("token", "");
    },
    change_notif_chat(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/account/notif_chat";
        let auth = context.getters.get_credential;
        axios
          .post(url, { value: payload }, { auth: auth })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    change_notif_msg(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/account/notif_msg";
        let auth = context.getters.get_credential;
        axios
          .post(url, { value: payload }, { auth: auth })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    change_notif_confirm(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/account/notif_confirm";
        let auth = context.getters.get_credential;
        axios
          .post(url, { value: payload }, { auth: auth })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    change_notif_request(context, payload) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/user/no_subscription";
        let auth = context.getters.get_credential;
        axios
          .put(url, { no_subscription: payload }, { auth: auth })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
