import axios from "axios";

export default {
  state: {
    prodotti: [],
    loading: false,
  },
  getters: {
    get_lista_prodotti: (state) => {
      return state.prodotti;
    },
    get_prodotto_by_id: (state) => (id) => {
      for (let i = 0; i < state.prodotti.length; i++) {
        for (let y = 0; y < state.prodotti[i].sub_categorie.length; y++) {
          for (
            let x = 0;
            x < state.prodotti[i].sub_categorie[y].prodotti.length;
            x++
          ) {
            let prod = state.prodotti[i].sub_categorie[y].prodotti[x];
            if (id === prod.id) {
              return prod;
            }
          }
        }
      }
    },
    get_prodotto_by_cod: (state) => (cod) => {
      for (let i = 0; i < state.prodotti.categorie.length; i++) {
        for (
          let y = 0;
          y < state.prodotti.categorie[i].sub_categorie.length;
          y++
        ) {
          for (
            let x = 0;
            x < state.prodotti.categorie[i].sub_categorie[y].prodotti.length;
            x++
          ) {
            let prod = state.prodotti.categorie[i].sub_categorie[y].prodotti[x];
            if (cod === prod.cod) {
              return prod;
            }
          }
        }
      }
    },
  },
  mutations: {
    set_prodotti(state, lista) {
      state.prodotti = lista;
    },
    set_loading(state, loading) {
      state.loading = loading;
    },
    azzera_prodotto(state, id) {
      for (let i = 0; i < state.prodotti.length; i++) {
        for (let y = 0; y < state.prodotti[i].sub_categorie.length; y++) {
          for (
            let x = 0;
            x < state.prodotti[i].sub_categorie[y].prodotti.length;
            x++
          ) {
            if (id === state.prodotti[i].sub_categorie[y].prodotti[x].id) {
              for (
                let col = 0;
                col <
                state.prodotti[i].sub_categorie[y].prodotti[x].colori.length;
                col++
              ) {
                state.prodotti[i].sub_categorie[y].prodotti[x].colori[
                  col
                ].numero = 0;
              }
            }
          }
        }
      }
    },
  },
  actions: {
    fetch_prodotti(context) {
      return new Promise((resolve, reject) => {
        context.commit("set_loading", true);
        let url = process.env.VUE_APP_API_ROOT + "/lista";
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_prodotti", res.data);
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            context.commit("set_loading", false);
          });
      });
    },
    switch_pref(context, prodotto) {
      return new Promise((resolve, reject) => {
        let status = prodotto.preferito ? "1" : "0";
        let url =
          process.env.VUE_APP_API_ROOT +
          "/switch_pref/" +
          prodotto.id +
          "/" +
          status;
        let auth = context.getters.get_credential;
        axios
          .put(url, {}, { auth: auth })
          .then(() => {
            resolve("ok");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
