export default {
    data() {
        return {
            // refresh variables
            refreshing: false,
            registration: null,
            updateExists: false,
        }
    },

    created() {
        // Listen for our custom event from the SW registration
        document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    },

    methods: {
        // Store the SW registration so we can send it a message
        // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
        // To alert the user there is an update they need to refresh for
        updateAvailable(event) {
            console.log("update available")
            this.registration = event.detail
            this.updateExists = true
        },

        // Called when the user accepts the update
        refreshApp() {
            this.updateExists = false
            console.log("refresh app")
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            console.log(this.registration)
            console.log(this.registration.waiting)
            if (!this.registration || !this.registration.waiting) return
            // send message to SW to skip the waiting and activate the new SW
            console.log("sending skip waiting")
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        },
    },
}