export default {
    state: {
        prod_dect_open: false
    },
    getters: {
        get_prod_dect: state => {
            return state.prod_dect_open
        }
    },
    mutations: {
        set_prod_dect_state(state, value) {
            state.prod_dect_open = value
        }
    }
}