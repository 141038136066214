import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import vapid from "../service/vapid";

export default {
  data() {
    return {
      notificationsSupported: false,
      show_notification_request: false,
      show_notification_denied: false,
      show_notification_ok: false,
      on_choosing: false,
      // no_sub: false,
    };
  },

  mounted() {
    if ("Notification" in window && "serviceWorker" in navigator) {
      console.log("[NOTIF] Notif supported");
      this.notificationsSupported = true;
      navigator.serviceWorker.addEventListener("message", (e) => {
        if (e.data == "open-chat") {
          this.$refs.chat.open();
        }
      });
    } else {
      console.log("[NOTIF] Notif NOT supported");
    }
    if (this.$route.query.chat == "true") {
      console.log("[firebase_msg.js] Fire open chat");
      setTimeout(() => {
        this.$refs.chat.open();
      }, 1000);
    }
  },

  methods: {
    checkSubscripions() {
      if (Notification.permission == "denied") {
        console.log("[NOTIF] Notifiche non permesse");
        return;
      }
      if (Notification.permission == "default") {
        this.show_notification_request = true;
      }
      if (Notification.permission == "granted") {
        this.init_firebase();
      }
    },
    show_request() {
      Notification.requestPermission().then((res) => {
        this.on_choosing = false;
        if (res == "granted") {
          this.show_notification_ok = true;
          this.init_firebase();
        } else {
          this.show_notification_denied = true;
        }
      });
    },
    init_firebase() {
      console.log("Inizializzo firebase");

      const firebaseConfig = {
        apiKey: "AIzaSyB5pz7DbT147g9-RD1Ux-pUft6Eq41Ducw",
        authDomain: "web-shop-2.firebaseapp.com",
        projectId: "web-shop-2",
        storageBucket: "web-shop-2.appspot.com",
        messagingSenderId: "750742685458",
        appId: "1:750742685458:web:9cc7e9d8aba7fc4a3f95cb",
      };

      // Initialize Firebase
      const firebase = initializeApp(firebaseConfig);

      const messaging = getMessaging(firebase);
      vapid.fetch_vapid().then((vapid_key) => {
        getToken(messaging, {
          vapidKey: vapid_key,
        }).then((token) => {
          console.log(token);
          vapid.send_subscription(token);
          onMessage(messaging, (payload) => {
            console.log(payload);
            console.log("[firebase-messaging-sw.js] Notifica Ricevuta");
            if (payload.data.tag == "chat") {
              let coord = JSON.parse(payload.data.coord);
              console.log(coord);
              this.$store.dispatch("confirm_notif_received", coord);
            }
          });
        });
      });
    },
  },
};
