import Vue from 'vue'
import VueRouter from 'vue-router'
//import store from '../store/index'

import root_ from '../views/Lista.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Root',
    component: root_
    // component: () => import(/* webpackChunkName: "root" */ '../views/About.vue')
  },
  {
    path: '/carrello',
    name: 'Carrello',
    component: () => import(/* webpackChunkName: "carrello" */ '../views/Carrello.vue')
  },
  {
    path: '/ordini',
    name: 'Ordini',
    component: () => import(/* webpackChunkName: "ordini" */ '../views/Ordini.vue')
  },
  {
    path: '/messaggi',
    name: 'Messaggi',
    component: () => import(/* webpackChunkName: "messaggi" */ '../views/Messaggi.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue')
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((from, to, next) => {
 //console.log("[ROUTER] Leaving " + from.name + " Entering " + to.name)
  next()
  // if (store.getters.get_prod_dect) {
  //   console.log("stop navigation")
  //   store.commit("set_prod_dect_state", false)
  //   next(false)
  // } else {
  //   next()
  // }
  
})

export default router
