import Vue from 'vue'
import Vuex from 'vuex'

import carrello from './modules/carrello'
import ordini from './modules/ordini'
import prodotti from './modules/prodotti'
import utente from './modules/utente'
import parametri from './modules/parametri'
import conversazioni from './modules/conversazioni'
import chat from './modules/chat'
import nav_guard from './modules/nav_guard'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    carrello,
    ordini,
    prodotti,
    utente,
    parametri,
    conversazioni,
    chat,
    nav_guard
  }
})
