export default {
  data() {
    return {
      deferredPrompt: null,
    };
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.deferredPrompt = e; 
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    install_pwa() {
      this.deferredPrompt.prompt();
    },
    dismiss_pwa() {
      this.deferredPrompt = null;
    },
  },
};
