import axios from "axios";

export default {
  state: {
    ordini: [],
  },
  getters: {
    get_lista_ordini: (state) => {
      return state.ordini;
    },
    get_ordine_by_numero: (state) => (numero) => {
      return state.ordini.find((ord) => {
        return ord.numero == numero;
      });
    },
  },
  mutations: {
    set_lista_ordini(state, ordini) {
      state.ordini = ordini;
    },
    add_ordine(state, ordine) {
      state.ordini.unshift(ordine);
    },
    rem_ordine(state, id) {
      let i = state.ordini.findIndex((ord) => ord._id.$oid == id);
      state.ordini.splice(i, 1);
    },
    set_ordine_accettato(state, id) {
      state.ordini.find((ordine) => ordine._id.$oid == id).stato = 1;
    },
    set_ordine_consegna(state, id) {
      state.ordini.find((ordine) => ordine._id.$oid == id).stato = 6;
    },
  },
  actions: {
    fetch_lista_ordini(context, payload) {
      return new Promise((resolve, reject) => {
        if (!payload.ts_inizio) {
          payload.ts_inizio = Math.floor(Date.now() / 1000) - 60 * 60 * 24 * 30; // ultimo mese
        }
        if (!payload.ts_fine) {
          payload.ts_fine = Math.floor(Date.now() / 1000);
        }
        let url =
          process.env.VUE_APP_API_ROOT +
          "/ordini/" +
          payload.ts_inizio +
          "/" +
          payload.ts_fine;
        let auth = context.getters.get_credential;
        axios
          .get(url, { auth: auth })
          .then((res) => {
            context.commit("set_lista_ordini", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    elimina_ordine(context, id) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/ordini/" + id;
        let auth = context.getters.get_credential;
        console.log("[ORDINI] elimino ordine");
        axios
          .delete(url, { auth: auth })
          .then(() => {
            context.commit("rem_ordine", id);
            resolve("ok");
          })
          .catch((err) => {
            console.log("[ORDINI] errore " + err);
            reject(err);
          });
      });
    },
    SOCKET_ORDINE_ACCETTATO(context, payload) {
      context.commit("set_ordine_accettato", payload);
    },
    SOCKET_CONSEGNA_PARTITA(context, payload) {
      context.commit("set_ordine_consegna", payload);
    },
  },
};
