import axios from 'axios'
import store from '../store/index'

export default {
    fetch_vapid: function () {
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_ROOT + "/vapid"
            let auth = store.getters.get_credential
            axios.get(url, { 'auth': auth })
                .then(res => {
                    resolve (res.data)
                })
                .catch(err => {
                    reject (err)
                })
        })


    },
    send_subscription: function (sub) {
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_ROOT + "/push-subscriptions/" + store.getters.get_utente.iva
            let auth = store.getters.get_credential
            axios.post(url, {token: sub}, { 'auth': auth })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })

    }
}