import axios from 'axios'
export default {
    state: {
        messaggi: [],
        destinatari: []
    },
    getters: {
        get_lista_messaggi: state => {
            return state.messaggi
        },
        get_destinatari: state => {
            return state.destinatari
        },
        get_numero_msg_nl: (state, getters) => {
            if (state.messaggi.length == 0) {
                return 0
            }
            return state.messaggi.map(msg => {
                return msg.messaggi.filter(msg => {
                    return msg.autore.iva != getters.get_utente.iva
                })
                    .filter(msg => {
                        return msg.letto == false
                    }).length
            }).reduce((tot, num) => tot + num)
        }
    },
    mutations: {
        set_messaggi(state, messaggi) {
            state.messaggi = messaggi
        },
        set_destinatari(state, dest) {
            state.destinatari = dest
        },
        set_msg_read_conversation(state, payload) {
            console.log("Payload: ")
            console.log(payload)
            state.messaggi.find(conv => conv.id == payload.id_conversazione).messaggi.find(msg => msg._id.$oid == payload.id_messaggio).letto = true
        },
        remove_conversation(state, id) {
            let index = state.messaggi.findIndex(conv => conv.id == id)
            state.messaggi.splice(index, 1)
        }
    },
    actions: {
        fetch_messaggi(context) {
            return new Promise((resolve, reject) => {
                let url = process.env.VUE_APP_API_ROOT + '/conversazioni'
                let auth = context.getters.get_credential
                axios.get(url, { 'auth': auth })
                    .then(res => {
                        console.log(res.data)
                        context.commit("set_messaggi", res.data)
                        resolve('ok')
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetch_destinatari(context) {
            axios
                .get(process.env.VUE_APP_API_ROOT + "/conversazioni/destinatari", {
                    'auth': context.getters.get_credential,
                })
                .then((res) => {
                    context.commit("set_destinatari", res.data)
                });
        },
        send_msg(context, payload) {
            return new Promise((resolve, reject) => {
                let url = process.env.VUE_APP_API_ROOT + '/conversazioni/' + payload.id_conversazione
                let auth = context.getters.get_credential
                axios.post(url, { messaggio: payload.body }, { 'auth': auth })
                    .then(() => {
                        context.dispatch("fetch_messaggi").
                            then(() => {
                                resolve()
                            })

                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        send_read_confirm(context, payload) {
            console.log("[CONVERS] mando conferma lettura")
            return new Promise((resolve, reject) => {
                let url = process.env.VUE_APP_API_ROOT + '/conversazioni/' + payload.id_conversazione + '/' + payload.id_messaggio
                let auth = context.getters.get_credential
                axios.post(url, {}, { 'auth': auth })
                    .then(() => {
                        console.log("[CONVERS] Conferma OK. Segno msg come letto")
                        context.commit("set_msg_read_conversation", payload)
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        new_conversation(context, payload) {
            return new Promise((resolve, reject) => {
                let url = process.env.VUE_APP_API_ROOT + '/conversazioni'
                let auth = context.getters.get_credential
                axios.post(url, { titolo: payload.titolo, destinatario: payload.destinatario, rif_ordine: payload.rif_ordine }, { 'auth': auth })
                    .then((res) => {
                        context.dispatch("send_msg", { id_conversazione: res.data.id, body: payload.msg })
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        delete_conversation(context, id) {
            return new Promise((resolve, reject) => {
                let url = process.env.VUE_APP_API_ROOT + '/conversazioni/' + id
                let auth = context.getters.get_credential
                axios.delete(url, { 'auth': auth })
                    .then(() => {
                        context.commit("remove_conversation", id)
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    }
}