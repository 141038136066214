//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//import fetch_all from "@/service/fetch_all.js";
export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      p_iva: "",
      password: "",
      iva_rules: [(v) => !!v || "Inserire Partita Iva"],
      pwd_rules: [() => this.pwd_ok == true || "Password Errata"],
      show_pwd: false,
      valid: true,
      pwd_ok: true,
      onLoad: false,
      remember: false,
    };
  },

  mounted() {
    if (localStorage.getItem("username")) {
      this.p_iva = localStorage.getItem("username");
      if (localStorage.getItem("password")) {
        this.password = localStorage.getItem("password");
        this.remember = true;
      }
    }
  },
  methods: {
    verify() {
      let auth = {
        username: this.p_iva,
        password: this.password,
      };
      this.onLoad = true;
      if (this.remember) {
        localStorage.setItem("username", this.p_iva);
        localStorage.setItem("password", this.password);
      }
      this.$store
        .dispatch("verifica_credenziali", auth)
        .then((res) => {
          if (res.status == 200) {
            this.$emit("login-ok");
          } else {
            this.pwd_ok = false;
            this.$refs.form.validate();
            this.pwd_ok = true;
          }
        })
        .catch(() => {
          alert("Errore di Connessione al server. Riprovare più tardi");
        })
        .finally(() => {
          this.onLoad = false;
        });
    },
  },
};
