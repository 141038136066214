import axios from "axios";

export default {
  state: {
    chat: null,
    loading: true,
    typing: false,
    file_loading: 0,
    img_loading: 0,
    archived: false,
  },
  getters: {
    get_chat_loading: (state) => {
      return state.loading;
    },
    get_chat: (state) => {
      return state.chat;
    },
    get_chat_nl: (state, getters) => {
      if (state.chat == null) {
        return 0;
      }
      return state.chat.messaggi
        .filter((msg) => msg.autore != getters.get_utente.iva)
        .filter((msg) => msg.letto != 2 && !msg.isNlFlag).length;
    },
    get_chat_typing: (state) => {
      return state.typing;
    },
    get_file_load_percent: (state) => {
      return state.file_loading;
    },
    get_msg_img_load: (state) => {
      return state.img_loading;
    },
    get_chat_archived: (state) => {
      return state.archived;
    },
  },
  mutations: {
    set_chat_loading(state, loading) {
      state.loading = loading;
    },
    set_chat(state, chat) {
      state.chat = chat;
      state.archived = false;
    },
    set_add_new_msg(state, msg) {
      state.chat.messaggi.push(msg);
    },
    set_msg(state, msg) {
      state.chat.messaggi = msg;
    },
    set_msg_read(state, id_msg) {
      state.chat.messaggi.find((msg) => msg._id == id_msg).letto = 2;
    },
    set_msg_sent(state, id_msg) {
      state.chat.messaggi.find((msg) => msg._id == id_msg).letto = 1;
    },
    set_chat_typing(state, typing) {
      state.typing = typing;
    },
    set_msg_file_load(state, payload) {
      let msg = state.chat.messaggi.find((msg) => msg._id == payload.msg._id);
      //msg = payload.msg
      msg.file_name = payload.msg.file_name;
      msg.file_size = payload.msg.file_size;
      msg.file_type = payload.msg.file_type;
      msg.loading = payload.value;
    },
    set_msg_img_load(state, payload) {
      let msg = state.chat.messaggi.find((msg) => msg._id == payload.msg._id);
      msg.height = payload.msg.height;
      msg.width = payload.msg.width;
      msg.loading = payload.value;
    },
    set_msg_img_wrong(state, id_msg) {
      let msg = state.chat.messaggi.find((msg) => msg._id == id_msg);
      msg.isImg = false;
      msg.isText = true;
      msg.testo = "Formato Immagine non supportato";
    },
    remove_chat_msg(state, id) {
      let index = state.chat.messaggi.findIndex((msg) => msg._id == id);
      state.chat.messaggi.splice(index, 1);
    },
    set_non_letto_flag(state, position) {
      let index = state.chat.messaggi.findIndex((msg) => msg.isNlFlag);
      if (index >= 0) {
        state.chat.messaggi.splice(index, 1);
      }
      if (position >= 0) {
        state.chat.messaggi.splice(position, 0, {
          isNlFlag: true,
          ts_creazione: state.chat.messaggi[position].ts_creazione,
        });
      }
    },
    set_chat_archived(state) {
      state.archived = true;
      state.chat = null;
    },
    set_chat_carico(state, payload) {
      state.chat.destinatario = payload;
    },
  },
  actions: {
    find_non_letto_index(context) {
      let index = context.state.chat.messaggi.findIndex(
        (msg) =>
          msg.autore != context.getters.get_utente.iva &&
          msg.letto != 2 &&
          !msg.isNlFlag
      );
      context.commit("set_non_letto_flag", index);
    },

    fetch_storico_chat(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/chat";
        let auth = context.getters.get_credential;
        context.commit("set_chat_loading", true);
        axios
          .get(url, { auth: auth })
          .then((res) => {
            console.log(res.data)
            if (res.data.code != 404) {
              context.commit("set_chat", res.data);
            } else {
              context.commit("set_chat", null);
            }
            resolve();
          })
          .catch((err) => {
            context.commit("set_chat", null);

            reject(err);
          })
          .finally(() => {
            context.commit("set_chat_loading", false);
          });
      });
    },
    create_chat(context) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/chat";
        let auth = context.getters.get_credential;
        context.commit("set_chat_loading", true);
        axios
          .post(url, {}, { auth: auth })
          .then((res) => {
            context.commit("set_chat", res.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    send_chat_msg(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/chat/" + payload.id_conversazione;
        let auth = context.getters.get_credential;
        axios
          .post(
            url,
            { text: payload.body, destinatario: payload.destinatario },
            { auth: auth }
          )
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    send_chat_img(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/chat/img_msg/" + payload.id_chat;
        let auth = context.getters.get_credential;
        let msg_id = null;
        axios
          .post(url, { destinatario: payload.destinatario }, { auth: auth })
          .then((res) => {
            msg_id = res.data.msg_id;
            let img_url = process.env.VUE_APP_API_ROOT + "/chat/img/" + msg_id;

            axios
              .post(img_url, payload.formData, { auth: auth })
              .then(() => {
                resolve();
              })
              .catch((err) => {
                context.commit("remove_chat_msg", msg_id);
                reject(err);
              });
          });
      });
    },
    send_chat_file(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/chat/file_msg/" + payload.id_chat;
        let auth = context.getters.get_credential;
        axios
          .post(url, { destinatario: payload.destinatario }, { auth: auth })
          .then((res) => {
            let msg_id = res.data.msg_id;
            let file_url =
              process.env.VUE_APP_API_ROOT + "/chat/file/" + msg_id;
            axios
              .post(file_url, payload.formData, { auth: auth })
              .catch((err) => {
                context.commit("remove_chat_msg", msg_id);
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    send_received_confirm_chat(context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          process.env.VUE_APP_API_ROOT + "/chat/consegnato/" + payload._id;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    send_read_confirm_chat(context, msg_id) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/chat/letto/" + msg_id;
        let auth = context.getters.get_credential;
        axios
          .post(url, {}, { auth: auth })
          .then(() => {
            context.commit("set_msg_read", msg_id);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    confirm_notif_received(context, coord) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_API_ROOT + "/push_received";
        let auth = context.getters.get_credential;
        axios
          .post(url, coord, { auth: auth })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    SOCKET_CHAT_CREATED(context, chat) {
      context.commit("set_chat", chat);
    },
    SOCKET_NEW_MESSAGE(context, msg) {
      if (msg.autore != context.getters.get_utente.iva) {
        context.dispatch("send_received_confirm_chat", msg);
      }
      context.commit("set_add_new_msg", msg);
    },
    SOCKET_MESSAGE_SENT(context, payload) {
      context.commit("set_msg_sent", payload._id);
    },
    SOCKET_MESSAGE_READ(context, payload) {
      context.commit("set_msg_read", payload._id);
    },
    SOCKET_MESSAGE_TYPING(context, payload) {
      context.commit("set_chat_typing", payload.typing);
    },
    SOCKET_IMAGE_LOADED(context, payload) {
      context.commit("set_msg_img_load", { msg: payload, value: false });
    },
    SOCKET_IMAGE_WRONG(context, payload) {
      context.commit("set_msg_img_wrong", payload._id);
    },
    SOCKET_FILE_LOADED(context, payload) {
      context.commit("set_msg_file_load", { msg: payload, value: false });
    },
    SOCKET_CHAT_ARCHIVED(context) {
      context.commit("set_chat_archived");
    },
    SOCKET_CHAT_PRESA_IN_CARICO(context, payload) {
      context.commit("set_chat_carico", payload);
    },
  },
};
